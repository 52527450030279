import PdLogger from '@/lib/PdLogger'
import router from '@/router'
import { pushSnackBarItem, renderNextSnackbar, snackBarHelper, SnackbarType } from "@/views/helpers/SnackbarHelper";
import { LoaderMixin } from "@/views/mixins/LoaderMixin";

// ----------------------------------------------------------------
// #region Functions
// ----------------------------------------------------------------
// All functions return UNDEFINED, if an error or redirect occurs.
// Otherwise, the response JSON is returned.

export async function fetchGET<ResponseJson>(options: {
	url: string
	urlParams?: {[key: string]: string | number}
}) {
	console.debug('Fetch GET - ' + options.url, options)
	const newUrl = _getUrlWithParameters(options.url, options.urlParams)
	const headers = _getCsrfHeader()
	try {
		const res = await fetch(newUrl, {method: 'GET', headers: headers})
		const body = await _checkResponse<ResponseJson>(res)

		if (body == null) return undefined
		return body
	} catch (e) {
		if (e instanceof Error) {
			PdLogger.errorFromException(e)
		}
		console.error('ERR - Fetch GET ::: Url: ', newUrl, 'Error: ', e)
		return undefined
	}
}

export async function fetchHEAD(options: {
	url: string
	urlParams?: {[key: string]: string | number}
}): Promise<{status: 'ok' | 'error', code: number}> {
	console.debug('Fetch HEAD - ' + options.url, options)
	const newUrl = _getUrlWithParameters(options.url, options.urlParams)
	const headers = _getCsrfHeader()
	try {
		const res = await fetch(newUrl, {method: 'HEAD', headers: headers})
		return {status: res.ok ? 'ok' : 'error', code: res.status}
	} catch (e) {
		console.error('ERR - Fetch HEAD ::: Url: ', newUrl, 'Error: ', e)
		if (e instanceof Error) {
			PdLogger.errorFromException(e)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			return {status: 'error', code: e.cause as number}
		}
		return {status: 'error', code: 0}
	}
}

export async function fetchGETwError<ResponseJson>(options: {
	url: string
	urlParams?: {[key: string]: string | number}
}): Promise<{status: 'error'; code: number} | {status: 'ok'; body: ResponseJson}> {
	console.debug('Fetch GET - ' + options.url, options)
	const newUrl = _getUrlWithParameters(options.url, options.urlParams)
	const headers = _getCsrfHeader()
	try {
		const res = await fetch(newUrl, {method: 'GET', headers: headers})
		const body = await _checkResponse<ResponseJson>(res)

		if (body == null) return {status: 'error', code: res.status}
		return {status: 'ok', body: body}
	} catch (e) {
		console.error('ERR - Fetch GET ::: Url: ', newUrl, 'Error: ', e)
		if (e instanceof Error) {
			PdLogger.errorFromException(e)
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			return {status: 'error', code: e.cause as number}
		}
		return {status: 'error', code: 0}
	}
}

export async function fetchPOST<ResponseJson>(options: {
	url: string
	urlParams?: {[key: string]: string | number}
	body?: {[key: string]: any}
	bodyRaw?: any
	formData?: FormData,
	ignoreRedirectTo?: boolean
}) {
	console.debug('Fetch POST - ' + options.url, options)
	const newUrl = _getUrlWithParameters(options.url, options.urlParams)
	const headers = _getCsrfHeader()
	if (options.bodyRaw) headers['Content-Type'] = 'application/json'
	const formData = options.bodyRaw
		? JSON.stringify(options.bodyRaw)
		: options.formData
		? options.formData
		: _getPostBodyForm(options.body)
	try {
		const res = await fetch(newUrl, {method: 'POST', headers: headers, body: formData})
		const body = await _checkResponse<ResponseJson>(res, options.ignoreRedirectTo)

		if (body == null) return undefined
		return body
	} catch (e) {
		if (e instanceof Error) {
			PdLogger.errorFromException(e)
		}
		console.error('ERR - Fetch POST ::: Url: ', newUrl, 'Error: ', e)
		return undefined
	}
}

export async function fetchPATCH<ResponseJson>(options: {
	url: string
	urlParams?: {[key: string]: string | number}
	body?: {[key: string]: any}
	bodyRaw?: any
	formData?: FormData
}) {
	const newUrl = _getUrlWithParameters(options.url, options.urlParams)
	const headers = _getCsrfHeader()
	if (options.bodyRaw) headers['Content-Type'] = 'application/json'
	const formData = options.bodyRaw
		? JSON.stringify(options.bodyRaw)
		: options.formData
		? options.formData
		: _getPostBodyForm(options.body)

	try {
		const res = await fetch(newUrl, {method: 'PATCH', headers: headers, body: formData})
		const body = await _checkResponse<ResponseJson>(res)
		if (body == null) return undefined
		return body
	} catch (e) {
		if (e instanceof Error) {
			PdLogger.errorFromException(e)
		}
		console.error('ERR - Fetch PATCH ::: Url: ', newUrl, 'Error: ', e)
		return undefined
	}
}

export async function fetchDELETE(options: {url: string}) {
	console.debug('Fetch DELETE - ' + options.url)
	const headers = _getCsrfHeader()
	try {
		const res = await fetch(options.url, {method: 'DELETE', headers: headers})
		if (!res.ok) throw new Error('Response not OK')
		return true
	} catch (e) {
		if (e instanceof Error) {
			PdLogger.errorFromException(e)
		}
		console.error('ERR - Fetch DELETE ::: Url: ', options.url, 'Error: ', e)
		return false
	}
}

// #endregion
// ----------------------------------------------------------------
// #region Helpers
// ----------------------------------------------------------------

function _getCookie(id: string) {
	const cookies = document.cookie
	const cookieArray = cookies.split('; ')

	for (let i = 0; i < cookieArray.length; i++) {
		const cookie = cookieArray[i]
		const [name, value] = cookie.split('=')

		if (name === id) {
			return decodeURIComponent(value)
		}
	}

	return null
}

export function _getCsrfHeader() {
	const csrfElem: null | HTMLInputElement = document.querySelector('[name=csrfmiddlewaretoken]')
	if (csrfElem == null || csrfElem.value == undefined) {
		console.warn('WARN::: CSRF - No Middleware Token Found')
		return {}
	}
	const headers = {'X-CSRFToken': csrfElem.value}

	return headers
}

function _getUrlWithParameters(url: string, parameters?: {[key: string]: string | number}) {
	let newUrl = url
	if (parameters) {
		const entries = Object.entries(parameters)
		if (entries.length < 1) return url

		newUrl += '?'
		for (let i = 0; i < entries.length; i++) {
			newUrl += `${entries[i][0]}=${entries[i][1]}`
			if (i < entries.length - 1) newUrl += '&'
		}
	}

	return newUrl
}

function _getPostBodyForm(data?: {[key: string]: any}) {
	let formData: FormData | undefined = undefined
	if (data) {
		formData = new FormData()
		for (const [key, value] of Object.entries(data)) {
			const newVal = typeof value === 'string' ? value : JSON.stringify(value)
			formData.append(key, newVal)
		}
	}
	return formData
}

function _redirect(to: string) {
	const apiPrefix = '/spa/api'
	const redirectUrl = to.startsWith(apiPrefix) ? to.slice(apiPrefix.length) : to

	// if route is resolvable, stay in the spa, else redirect to the django app
	const resolved = router.resolve({path: redirectUrl})
	if (resolved.matched.length > 0) {
		console.debug('REDIRECT ::: Using Vue Router, redirecting to: ', redirectUrl)
		router.push(redirectUrl)
	} else {
		console.debug('REDIRECT ::: location.href, redirecting to: ', redirectUrl)
		location.href = redirectUrl
	}
}

// Returns NULL if no further actions are necessary. Handles Redirects and 403. Returns the response json otherwise
async function _checkResponse<ResponseJson>(res: Response, ignoreRedirectTo=false) {
	if (res.ok) {
		// Direct Redirect
		if (res.redirected) {
			if (res.url && res.url.indexOf('/account/login/?next=') > -1) {
				const hash = window.location.hash
				let redirectUrl = '/account/login/'
				if (hash) {
					redirectUrl += '?next=' + hash
				}
				console.warn('INFO - res.redirect ::: Redirecting to :', redirectUrl)
				location.href = redirectUrl
				return null
			}
		}
		const json: ResponseJson = await res.json()
		// Indirect Redirect
		if (!ignoreRedirectTo && json['redirect_to']) {
			_redirect(json['redirect_to'])
		}
		return json
	} else {
		// status was not ok
		const json: ResponseJson = await res.json()
		if (json['error_type'] && json['error_type'] === 'spa_api_exception') {
			// catch api exceptions
			console.error('ERR - API Exception ::: ', json)

			pushSnackBarItem(snackBarHelper(json['headline'], json['detail'], SnackbarType.ERROR))
			renderNextSnackbar()

			LoaderMixin.methods.hideLoader()
			return null
		}

		if (res.status === 403 || res.status === 401) {
			console.warn('ERR - 403 ::: Authentication expired, redirecting to login.')
			const hash = window.location.hash;
			let redirectUrl = '/account/login/';
			if (hash) {
				redirectUrl += '?next=' + hash;
			}
			location.href = redirectUrl
			return null
		}
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		throw new Error('STATUS: ' + res.status + ' | ' + res.statusText, {cause: res.status})
	}
}

interface IFetchHeaders {
	'X-CSRF-TOKEN'?: string
}

// #endregion
// ----------------------------------------------------------------
